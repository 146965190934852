import moment from "moment"
import React, { useState } from "react"
import arrayFormat from "../../../common/arrayUseInProject/arrayFormat"
import queryString from "query-string"

export const defineTableListCompany = ({ location }) => {
  const query = queryString.parse(location.search)
  return [
    {
      title: "企業ID",
      dataIndex: "id",
      width: 100,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "企業名",
      dataIndex: ["name"],
      width: 300,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "業態",
      dataIndex: "user_type",
      width: 170,
      render: (user_type) => (
        <div className="textCustom">
          {arrayFormat.filter((el) => el.type == user_type)[0]?.name}
        </div>
      ),
    },
    // {
    //   title: "ステータス",
    //   dataIndex: "status",
    //   width: 110,
    //   render: (text) => <div className="textCustom">{text}</div>,
    // },
    {
      title: "登録日",
      dataIndex: "created_at",
      sorter: true,
      sortOrder: query?.sorterField == "created_at" ? query?.order : null,
      width: 108,
      render: (text) => (
        <div className="textCustom">{moment(text).format("YYYY/MM/DD")}</div>
      ),
    },
    {
      title: "最終ログイン",
      dataIndex: "last_action",
      sortOrder: query?.sorterField == "last_action" ? query?.order : null,
      sorter: true,
      width: 150,
      render: (text) => (
        <div className="textCustom">{moment(text).format("YYYY/MM/DD")}</div>
      ),
    },
    {
      title: "商品登録数",
      dataIndex: "total_product",
      width: 150,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "口座登録",
      width: 120,
      render: (_, record) => {
        return <div>{record?.branch_number ? "設定済み" : "未設定"}</div>
      },
    },
    {
      title: "支払情報",
      dataIndex: "payment_information",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record?.np_payment || record?.gmo_payment ? "設定済み" : "未設定"}
          </div>
        )
      },
    },
    {
      title: "手数料設定",
      width: 120,
      render: (_, record) => {
        return <div>{record?.applicationFee_check ? "設定済み" : "未設定"}</div>
      },
    },
  ]
}
