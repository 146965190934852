import React, { useEffect, useState } from "react"
import { Spin, Table, Space, Select, Button } from "antd"
import { getListCompany } from "../../../services/superAdManageCompany"

import "../style.scss"
import { paginationCustom } from "../../../components/paginationCustom"
import { defineTableListCompany } from "./defineTableListCompany"
import SearchTableListCompany from "./SearchTableListCompany"
import { navigate } from "gatsby"
import queryString from "query-string"

function ListCompany({ location }) {
  const [isLoading, setIsLoading] = useState(false)
  const [listCompany, setListCompany] = useState(null)
  const [total, setTotal] = useState(0)

  const [bulkOperation, setBulkOperation] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableParams, setTableParams] = useState(null)
  const handleGetListCompany = async () => {
    setIsLoading(true)
    const query = queryString.parse(location.search)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: query?.keyword ? query?.keyword : null,

      user_type:
        query?.user_type?.length > 0 ? JSON.stringify(query?.user_type) : null,
    }
    if (query?.order && query?.sorterField) {
      switch (query?.order) {
        case "ascend":
          dataSent.orderASC = [query?.sorterField]
          break
        case "descend":
          dataSent.orderDESC = [query?.sorterField]
          break
      }
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      if (
        response.data.count &&
        Math.ceil(response.data.count / 10) < Number(query?.pageCurrent)
      ) {
        const newQueryStr = queryString.stringify({
          ...query,
          pageCurrent: 1,
        })
        navigate(
          `/super-sales-commission-setting-company/?${queryString.stringify(
            newQueryStr
          )}`,
          undefined,
          {
            shallow: false,
          }
        )
        return
      }
      setListCompany(response.data.stores)
      setTotal(response.data.count)

      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (tableParams) {
      handleGetListCompany()
    }
  }, [tableParams])

  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(
      `/super-sales-commission-setting-company/?${queryString.stringify(
        newQueryStr
      )}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columns = defineTableListCompany({ location })

  const deleteOperation = async () => {
    setSubmitting(true)
    if (bulkOperation !== "deleteAll" || selectedRowKeys.length === 0) {
      setSubmitting(false)
      return
    }
  }
  const handleClickSetAll = () => {
    navigate(`setting?storeId=all`)
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/super-sales-commission-setting-company/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div
      style={{
        width: "100%",
        // padding: "16px 20px",
        background: "#FFFFFF",
        borderRadius: "6px",
      }}
    >
      <Spin spinning={isLoading}>
        <div className="pb-5">
          <SearchTableListCompany location={location} loading={isLoading} />
        </div>
        <div className="border border-[rgba(0,0,0,0.06)]">
          <div className="p-[10px] h-[60px] bg-[#F3F3F3] relative">
            <Button
              type="primary"
              className="!absolute top-[14px] right-[26px]"
              onClick={handleClickSetAll}
            >
              全企業販売手数料設定
            </Button>
            {/* <Space direction="vertical">
              <Space style={{ paddingBottom: 10 }}>
                <div className="text-[#333]">{"一括操作"}</div>
                <Select
                  allowClear
                  placeholder={"操作を選択"}
                  style={{ width: 180 }}
                  value={bulkOperation}
                  onChange={(e) => {
                    setBulkOperation(e)
                  }}
                >
                  <Select.Option value={"deleteAll"}>
                    {"一括削除"}
                  </Select.Option>
                </Select>
                <Button
                  loading={submitting}
                  disabled={selectedRowKeys?.length == 0}
                >
                  {"実行"}
                </Button>
              </Space>
            </Space> */}
          </div>
          {tableParams && (
            <Table
              style={{ cursor: "pointer" }}
              columns={columns}
              dataSource={listCompany}
              loading={isLoading}
              rowSelection={rowSelection}
              scroll={{ x: 1500 }}
              rowKey="id"
              onChange={handleTableChange}
              onRow={(record) => {
                return {
                  onClick: () => navigate(`setting?storeId=${record.id}`),
                }
              }}
              pagination={{
                ...tableParams.pagination,
                total: total,
                itemRender: paginationCustom,
                showSizeChanger: false,
                showTotal: (total, range) => (
                  <div>
                    {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                    {total}件)
                  </div>
                ),
              }}
            />
          )}
        </div>
      </Spin>
    </div>
  )
}

export default ListCompany
